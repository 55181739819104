import React, { useEffect, useState } from 'react';
import AppStoreQR from '../Assets/AppStoreQR.png';
import PlayStoreQR from '../Assets/PlayStoreQR.png';
import Close from '../Assets/Close-icon.svg';

function QRModal({ displayModal, closeModal }) {
    const [visible, setVisible] = useState(false);

    const handleCloseModal = () => {
        closeModal();
    }

    const handleAppStoreClick = () => {
        window.open("https://apps.apple.com/in/app/hyprcom-socio-commerce-app/id6504120554", "_blank");
    }

    const handlePlayStoreClick = () => {
        window.open("https://play.google.com/store/apps/details?id=com.mobileapp_hyprcom", "_blank");
    }

    useEffect(() => {
        if(displayModal) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }, [displayModal]);

    return (
        <div className={`${visible ? 'opacity-100 visible bg-[#00000080] backdrop-blur-sm' : 'opacity-0 invisible'} font-urbanist-regular transition-all duration-100 ease-in-out fixed z-50 w-full h-full top-0 left-0 flex justify-center items-center`}>
            <div className="bg-[#121212] p-8 flex flex-col gap-8 rounded-2xl relative max-[512px]:w-[90%]">
                <h1 className='font-bold text-[32px] leading-[44px] max-[1280px]:text-3xl max-[1024px]:text-2xl text-[#FFFFFF] text-center max-[512px]:hidden'>Scan QR Code</h1>

                <h1 className='font-bold text-[32px] leading-[44px] max-[1280px]:text-3xl max-[1024px]:text-2xl text-[#FFFFFF] text-center min-[512px]:hidden max-[512px]:block'>Download our App</h1>

                <button onClick={handleCloseModal} className="absolute top-8 right-8 max-[1280px]:top-7 max-[1280px]:right-7 max-[1024px]:top-6 max-[1024px]:right-6 size-11 rounded-full bg-[#232327] outline-none flex items-center justify-center">
                    <img src={Close} alt="" />
                </button>

                <div className="flex items-center gap-8 max-[512px]:hidden">
                    <div className="flex flex-col items-center gap-6">
                        <p className='font-medium text-[20px] leading-8 max-[1280px]:text-base max-[1024px]:text-sm text-[#B0B0B0]'>Scan the App Store QR code to download the app</p>

                        <div className="size-80 max-[1280px]:size-64 max-[1024px]:size-52 rounded-lg overflow-hidden">
                            <img className='w-full h-full object-cover' src={AppStoreQR} alt="" />
                        </div>

                        <div className="w-full flex items-center gap-2">
                            <div className="flex-grow h-[1px] bg-[#B0B0B0]"></div>

                            <p className='font-medium text-[16px] leading-8 max-[1280px]:text-[14px] max-[1280px]:leading-6 max-[1024px]:text-[12px] max-[1024px]:leading-5 text-[#B0B0B0]'>Or visit the link directly</p>

                            <div className="flex-grow h-[1px] bg-[#B0B0B0]"></div>
                        </div>

                        <button onClick={handleAppStoreClick} className="w-full px-6 py-3 bg-[#00C853] rounded-md font-bold text-[18px] leading-7 max-[1280px]:text-base max-[1024px]:text-sm text-[#121212]">Continue on App Store</button>
                    </div>

                    <div className="self-stretch w-[1px] bg-[#B0B0B0]"></div>

                    <div className="flex flex-col items-center gap-6">
                        <p className='font-medium text-[20px] leading-8 max-[1280px]:text-base max-[1024px]:text-sm text-[#B0B0B0]'>Scan the Play Store QR code to download the app</p>

                        <div className="size-80 max-[1280px]:size-64 max-[1024px]:size-52 rounded-lg overflow-hidden">
                            <img className='w-full h-full object-cover' src={PlayStoreQR} alt="" />
                        </div>

                        <div className="w-full flex items-center gap-2">
                            <div className="flex-grow h-[1px] bg-[#B0B0B0]"></div>

                            <p className='font-medium text-[16px] leading-8 max-[1280px]:text-[14px] max-[1280px]:leading-6 max-[1024px]:text-[12px] max-[1024px]:leading-5 text-[#B0B0B0]'>Or visit the link directly</p>

                            <div className="flex-grow h-[1px] bg-[#B0B0B0]"></div>
                        </div>

                        <button onClick={handlePlayStoreClick} className="w-full px-6 py-3 bg-[#00C853] rounded-md font-bold text-[18px] leading-7 max-[1280px]:text-base max-[1024px]:text-sm text-[#121212]">Continue on Playstore</button>
                    </div>
                </div>

                <div className="flex-col gap-8 min-[512px]:hidden max-[512px]:flex">
                    <div className="flex flex-col items-center gap-6">
                        <button onClick={handleAppStoreClick} className="w-full px-6 py-3 bg-[#00C853] rounded-md font-bold text-[18px] leading-7 max-[1280px]:text-base max-[1024px]:text-sm text-[#121212]">Download on App Store</button>
                    </div>

                    <div className="flex flex-col items-center gap-6">
                        <button onClick={handlePlayStoreClick} className="w-full px-6 py-3 bg-[#00C853] rounded-md font-bold text-[18px] leading-7 max-[1280px]:text-base max-[1024px]:text-sm text-[#121212]">Download on Playstore</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QRModal;
