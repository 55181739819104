import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Hamburger from "../Assets/menu-icon.svg";
import Brand from "../Assets/Hyprcom-icon.svg";
import CloseIcon from "../Assets/Close-icon.svg";
import "./Navbar.css";
import WaitlistForm from "./WaitlistForm";

const Navbar = ({ toggleBlur, setIsFormOpen, isFormOpen, }) => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 720);
  const [showWaitlistForm, setShowWaitlistForm] = useState(false);

  // const handleShowWaitlistForm = () => {
  //   handleToggleNavbar();
  //   setIsFormOpen((prevIsFormOpen) => !prevIsFormOpen);
  // };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 720);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleToggleNavbar = () => {
    toggleBlur();
    const menuElementsVisible = !showNavbar;

    setShowNavbar(menuElementsVisible);

    if (isSmallScreen) {
      if (menuElementsVisible) {  
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    }
  };

  const handleLoginButton = () => {
    window.open("https://seller.hyprcom.in/", "_blank");
  }

  return (
    <>
      <nav className={`navbar  ${isFormOpen ? "form-open" : ""} `}>
        <div className="container">
          <div className="logo">
            <NavLink to="/">
              <img src={Brand} alt="" className="hyprcom-icon" />
            </NavLink>
          </div>
          {isSmallScreen && !showNavbar && (
            <div className="menu-icon">
              <img src={Hamburger} alt="open" onClick={handleToggleNavbar} />
            </div>
          )}
          {showNavbar && isSmallScreen && (
            <img src={CloseIcon} alt="Close" onClick={handleToggleNavbar} />
          )}
          {(isSmallScreen && showNavbar) || !isSmallScreen ? (
            <div className={`nav-elements ${showNavbar ? "active" : ""}`}>
              <ul>
                <li>
                  <NavLink to="/">Home</NavLink>
                </li>
                <li>
                  <NavLink to="/about">About Hyprcom</NavLink>
                </li>
                <li>
                  <NavLink to="/contact">Contact Us</NavLink>
                </li>
                <li>
                  {/* <div id="join-waitlist" onClick={handleShowWaitlistForm}>
                    JOIN WAITLIST
                  </div> */}
                  <button onClick={handleLoginButton} className="px-4 py-2 rounded-md bg-[#00C853] font-bold text-[14px] leading-7 text-[#121212] outline-none">Login as Seller</button>
                </li>
              </ul>
            </div>
          ) : null}
        </div>
      </nav>
      {showWaitlistForm && (
        <WaitlistForm
          onClose={() => setShowWaitlistForm(false)}
          isFormOpen={isFormOpen}
          setIsFormOpen={setIsFormOpen}
        />
      )}
    </>
  );
};

export default Navbar;
