import React from "react";
import Navbar from "../Components/Navbar";
import Property1Default1 from "../Components/Property1Default1";
import Property1Default from "../Components/Property1Default";
import { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import FAQContainer from "../Components/FAQContainer";
import StayInTheLoopSection1 from "../Components/StayInTheLoopSection1";
import BottomFooter from "../Components/BottomFooter";
import icon from "../Assets/Redefining.png";
import icon2 from "../Assets/verifiedchecksvgrepocom-1-1@2x.png";
import icon3 from "../Assets/main.png";
import icon4 from "../Assets/main2.png";
import icon5 from "../Assets/FutureIcon.png";
import WaitlistForm from "../Components/WaitlistForm";
import "./About-Hyprcom.css";
import "./PreLaunchWebsiteDesign.css";
import BrandForm from "../Components/BrandForm";
import WaitlistAlreadyMemberMessage from "../Components/WaitlistAlreadyMemberMessage";
import WaitlistSuccessfulMessage from "../Components/WaitlistSuccessfulMessage";
import BrandFormSubmissionMessage from "../Components/BrandFormSubmissionMessage";
import { Helmet } from "react-helmet";

const About = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [isSmallScreen] = useState(window.innerWidth <= 720);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isBrandFormOpen, setIsBrandFormOpen] = useState(false);
  const [isAlreadyMember, setIsAlreadyMember] = useState(false);
  const [isSuccessfulMember, setIsSuccessfulMember] = useState(false);
  const [isBrandAdded, setIsBrandAdded] = useState(false);

  const formRef = useRef(null);

  const toggleBlur = () => {
    setShowNavbar((prevShowNavbar) => !prevShowNavbar);
  };

  // Function to scroll to BottomFooter
  const scrollToBottomFooter = () => {
    const footerElement = document.querySelector(".frame-container");
    if (footerElement) {
      window.scrollTo({
        top: footerElement.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const handleShowBrandForm = () => {
    setIsBrandFormOpen(true);
  };

  const handleShowWaitlistForm = () => {
    setIsFormOpen(true);
  };

  useEffect(() => {
    if (isBrandAdded) {
      setIsBrandFormOpen(false);
    }

    if (isSuccessfulMember) {
      setIsFormOpen(false);
    }

    if (isAlreadyMember) {
      setIsFormOpen(false);
    }

    if (isBrandFormOpen && formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }

    if (isFormOpen && formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }

    if (isAlreadyMember && formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }

    if (isSuccessfulMember && formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }

    if (isBrandAdded && formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }

    if (
      isFormOpen ||
      isBrandFormOpen ||
      isBrandAdded ||
      isSuccessfulMember ||
      isAlreadyMember
    ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [
    isFormOpen,
    isBrandFormOpen,
    isAlreadyMember,
    isSuccessfulMember,
    isBrandAdded,
  ]);

  return (
    <div
      className={`aboutusPage ${isFormOpen ? "form-open" : ""} `}
      ref={formRef}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          About Hyprcom: Our Mission in Transforming E-Commerce Shopping
        </title>
        <meta
          name="description"
          content="Learn about Hyprcom's mission to redefine e-commerce with a focus on sustainability, quality, and innovation in the fashion industry. Our app brings unique features for an unparalleled shopping experience."
        />
        <meta
          name="keywords"
          content="about Hyprcom, e-commerce innovation, sustainable shopping, fashion app, shopping app mission, brand identity, quality products, sustainable e-commerce, fashion industry revolution"
        />
        <meta
          property="og:title"
          content="About Hyprcom: Our Mission in Transforming E-Commerce Shopping"
        />
        <meta
          property="og:description"
          content="Learn about Hyprcom's mission to redefine e-commerce with a focus on sustainability, quality, and innovation in the fashion industry. Our app brings unique features for an unparalleled shopping experience."
        />
        <meta property="og:url" content="https://hyprcom.in/about" />
        <meta
          name="twitter:title"
          content="About Hyprcom: Our Mission in Transforming E-Commerce Shopping"
        />
        <meta
          name="twitter:description"
          content="Learn about Hyprcom's mission to redefine e-commerce with a focus on sustainability, quality, and innovation in the fashion industry. Our app brings unique features for an unparalleled shopping experience."
        />
        <link rel="canonical" href="https://hyprcom.in/about" />
      </Helmet>
      {isFormOpen && (
        <WaitlistForm
          onClose={() => setIsFormOpen(false)}
          onAlreadyMember={() => setIsAlreadyMember(true)}
          onSuccessfulyMember={() => setIsSuccessfulMember(true)}
        />
      )}
      {isBrandFormOpen && (
        <BrandForm
          onClose={() => setIsBrandFormOpen(false)}
          onBrandAdded={() => setIsBrandAdded(true)}
        />
      )}
      {isSuccessfulMember && (
        <WaitlistSuccessfulMessage
          onFollowSocialMedia={scrollToBottomFooter}
          onClose={() => setIsSuccessfulMember(false)}
        />
      )}
      {isAlreadyMember && (
        <WaitlistAlreadyMemberMessage
          onClose={() => setIsAlreadyMember(false)}
        />
      )}
      {isBrandAdded && (
        <BrandFormSubmissionMessage onClose={() => setIsBrandAdded(false)} />
      )}
      <div
        className={`forNav1  ${isFormOpen ? "form-open" : ""} ${
          isBrandFormOpen ? "form-open" : ""
        } ${isAlreadyMember ? "form-open" : ""} ${
          isSuccessfulMember ? "form-open" : ""
        } ${isBrandAdded ? "form-open" : ""}`}
      >
        <div className="forbgImage">
          <Navbar
            className={`nav  ${isFormOpen ? "form-open" : ""} `}
            toggleBlur={toggleBlur}
            isSmallScreen={isSmallScreen}
            setIsFormOpen={setIsFormOpen}
          />
          <div className={`aboutPage1 ${showNavbar ? "blur" : ""}`}>
            <div id="wrapper1">
              <span className="innerText1">
                Hyprcom is more than Online Marketplace;{" "}
              </span>
              <span className="innerText2">
                it's a Community of Storytellers
              </span>
            </div>
            <div id="wrapper2">
              At Hyprcom, we're not just building an app; we're crafting an
              experience. Your journey with us begins by joining the waitlist –
              an exclusive pass to a shopping revolution waiting to unfold.
            </div>
            <div id="primary-button-parent">
              <Property1Default1 setIsFormOpen={setIsFormOpen} />
              <Property1Default setIsBrandFormOpen={setIsBrandFormOpen} />
            </div>
          </div>
        </div>
        <div className={`aboutPage2 ${showNavbar ? "blur" : ""}`}>
          <div id="wrapper3">
            <span className="innerText3">Redefining the </span>
            <span className="innerText4">Shopping Landscape</span>
          </div>
          <div className="az">
            <img src={icon} alt="" id="icon1" />
          </div>
          <div className="wrapper4">
            <div className="innerText5">
              Our Story: Nurturing the Seed of Innovation
            </div>
            <div className="innerText6">
              In the bustling world of e-commerce, Saiful Ameen emerged as a
              visionary, driven by a passion to redefine how we experience
              fashion in the digital age. Saiful, a seasoned product guy and
              founder of Hyprcom with an innate understanding of technology and
              a keen eye for trends, embarked on a journey to revolutionize the
              online shopping landscape.
            </div>
            <div className="innerText6">
              The spark that ignited Hyprcom came from Saiful's realization that
              the world needed a platform where fashion wasn't just bought but
              experienced. His journey began with a simple yet profound
              question: "How can we merge the elegance of fashion with the power
              of technology to create a truly immersive and personalized
              shopping adventure?"
            </div>
            <div className="innerText6">
              Saiful's entrepreneurial spirit and relentless pursuit of
              innovation led to the birth of Hyprcom. With a team of 9
              like-minded individuals, he set out to create a platform that
              transcends traditional e-commerce boundaries. The vision was clear
              - to not just sell fashion but to curate an experience that
              resonates with each user's unique style.
            </div>
            <div className="innerText6">
              Saiful's journey is not just about building a brand; it's about
              crafting a future where technology and fashion seamlessly coexist,
              elevating the very essence of online shopping. Through Hyprcom,
              Saiful Ameen invites you to join him on this transformative
              adventure, where the boundaries of fashion and technology
              dissolve, and a new era of shopping emerges.
            </div>
          </div>
        </div>
        <div className={`aboutPage3 ${showNavbar ? "blur" : ""}`}>
          <div className="userCahllenge">
            <div className="wrapper5">
              <span className="innerText7">User Challenges in </span>
              <span className="innerText8">Traditional Online Shopping</span>
            </div>
            <div className="wrapper6">
              In the traditional online shopping landscape, users grapple with
              numerous challenges – the paradox of overwhelming choices, the
              quest for genuine and sustainable products, and the absence of a
              personalized touch.
            </div>
          </div>
          <div className="hyprcomResponds">
            <div className="respondsWrapper">
              <div className="howHyprcomResponds1">How Hyprcom Responds:</div>
            </div>
            <div className="howHyprcomResponds2">
              <div className="curatedDiscoveries">
                <img
                  src={icon2}
                  alt=""
                  className="verified-check-svgrepo-com-1-icon2"
                />
                <div className="wrapper7">
                  <div className="innerText9">Curated Discoveries</div>
                  <div className="innerText10">
                    Hyprcom curates a selection of high-quality brands,
                    providing users with a refined and authentic shopping
                    experience tailored to their preferences.
                  </div>
                </div>
              </div>
              <div className="centricPersonalization">
                <img
                  src={icon2}
                  alt=""
                  className="verified-check-svgrepo-com-1-icon2"
                />
                <div className="wrapper7">
                  <div className="innerText9">User-Centric Personalization</div>
                  <div className="innerText10">
                    Through cutting-edge algorithms, we deliver personalized
                    recommendations, ensuring every choice aligns seamlessly
                    with your unique style.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="ac" onClick={handleShowWaitlistForm}>
            <NavLink>
              <b id="ab">JOIN WAITLIST</b>
            </NavLink>
          </div>
        </div>
        
        {/* <div className={`aboutPage4 ${showNavbar ? "blur" : ""}`}>
          <div className="brandLimitations">
            <div className="wrapper8">
              <span className="innerText11">Brand Limitations </span>
              <span className="innerText12">
                in Traditional Online Shopping
              </span>
            </div>
            <div className="brandsToo">
              Brands, too, face constraints within the traditional online
              shopping model – limited visibility, transactional customer
              relationships, and the struggle to stand out amid fierce
              competition.
            </div>
          </div>
          <div className="hyprcomResponds2">
            <div className="wrapper9">
              <div className="wrapper10">
                <div className="innerText13">How Hyprcom Responds</div>
                <div className="wrapper11">
                  <div className="innerText14">Individualized Brand Spaces</div>
                  <div className="innerText15">
                    Each brand on Hyprcom receives a dedicated store – a canvas
                    to express identity, share their story, and connect with
                    customers on a profound level.
                  </div>
                </div>
                <div className="wrapper12">
                  <div className="innerText14">Community Engagement</div>
                  <div className="innerText15">
                    We foster an environment where brands can engage directly
                    with their audience, building relationships that transcend
                    mere transactions
                  </div>
                </div>
              </div>
              <div className="wrapper13">
                <NavLink className="nav-style" onClick={handleShowBrandForm}>
                  <div className="onboard-on-hyprcom-wrapper">
                    <b className="onboard-on-hyprcom1">ONBOARD ON HYPRCOM</b>
                  </div>
                </NavLink>
                <NavLink className="nav-style">
                  <div className="schedule-a-call-with-us-wrapper">
                    <b className="onboard-on-hyprcom2">
                      SCHEDULE A CALL WITH US
                    </b>
                  </div>
                </NavLink>
              </div>
            </div>
            <div className="brandLimitationImage">
              <img src={icon4} alt="" className="brandimg2" />
              <img src={icon3} alt="" className="brandimg" />
            </div>
          </div>
        </div> */}

        <div className={`${showNavbar ? "blur" : ""} px-[100px] max-[1080px]:px-[50px] py-16 flex flex-col gap-16 font-urbanist-regular`}>
          <div className="flex flex-col gap-4">
            <h1 className="font-bold text-[52px] leading-[78px] max-[720px]:text-3xl text-center text-[#FFFFFF]"><span className="text-[#00C853]">Brand Limitations</span> in Traditional Online Shopping</h1>

            <p className="font-normal text-[32px] leading-[48px] max-[720px]:text-xl text-center text-[#B0B0B0]">Brands, too, face constraints within the traditional online shopping model – limited visibility, transactional customer relationships, and the struggle to stand out amid fierce competition.</p>
          </div>

          <div className="flex max-[1080px]:flex-col gap-6">
            <div className="w-[57%] max-[1152px]:w-[50%] max-[1080px]:w-full flex flex-col gap-16">
              <div className="flex flex-col gap-8">
                <h1 className="font-semibold text-[24px] leading-7 text-[#FFFFFF]">How Hyprcom Responds</h1>

                <div className="flex flex-col gap-5">
                  <div className="p-6 flex flex-col gap-3 border-b-[3px] border-[#00F260] rounded-bl-xl rounded-br-xl">
                    <h1 className="font-semibold text-2xl text-[#FFFFFF]">Individualized Brand Spaces</h1>

                    <p className="font-medium text-base text-[#B0B0B0]">Each brand on Hyprcom receives a dedicated store – a canvas to express identity, share their story, and connect with customers on a profound level.</p>
                  </div>

                  <div className="p-6 flex flex-col gap-3 border-b-[3px] border-[#00F260] rounded-bl-xl rounded-br-xl">
                    <h1 className="font-semibold text-2xl text-[#FFFFFF]">Community Engagement</h1>

                    <p className="font-medium text-base text-[#B0B0B0]">We foster an environment where brands can engage directly with their audience, building relationships that transcend mere transactions.</p>
                  </div>
                </div>
              </div>

              <div className="flex items-center gap-3">
                <NavLink className="nav-style" onClick={handleShowBrandForm}>
                  <div className="onboard-on-hyprcom-wrapper">
                    <b className="onboard-on-hyprcom1">ONBOARD ON HYPRCOM</b>
                  </div>
                </NavLink>
                <NavLink className="nav-style">
                  <div className="schedule-a-call-with-us-wrapper">
                    <b className="onboard-on-hyprcom2">
                      SCHEDULE A CALL WITH US
                    </b>
                  </div>
                </NavLink>
              </div>
            </div>

            <div className="w-[43%] max-[1152px]:w-[50%] max-[1080px]:w-full relative flex justify-center">
              <img className="h-full w-auto" src={icon3} alt="" />

              <img className="absolute bottom-0 max-[1152px]:h-[96%]" src={icon4} alt="" />
            </div>
          </div>
        </div>

        <div className={`aboutPage5 ${showNavbar ? "blur" : ""}`}>
          <div className="wrapper5">
            <span className="innerText16">Crafting a Community of </span>
            <span className="innerText17">Authenticity and Innovation</span>
          </div>
          <div className="hyprcomValues">
            <div className="innerText18">Hyprcom Values</div>
            <div className="innerText19">
              At Hyprcom, our values serve as the bedrock of everything we do.
              We are not just an e-commerce platform; we are a community, a
              movement that believes in shaping a future where authenticity and
              innovation thrive. Our values are the guiding principles that
              steer us forward:
            </div>
          </div>
          <div className="wrapper14">
            <div className="wrapper15">
              <div className="wrapper12">
                <div className="innerText14">Authenticity</div>
                <div className="innerText15">
                  We celebrate authenticity in all its forms – in the products
                  we curate, the stories we share, and the connections we forge.
                  Hyprcom is a space where genuine experiences and real
                  connections take center stage.
                </div>
              </div>
              <div className="wrapper12">
                <div className="innerText14">Community-Centric</div>
                <div className="innerText15">
                  Hyprcom is more than a platform; it's a community. We believe
                  in the power of shared experiences, and our community-centric
                  approach fosters connections that go beyond transactions.
                </div>
              </div>
            </div>
            <div className="wrapper16">
              <div className="wrapper12">
                <div className="innerText14">Innovation</div>
                <div className="innerText15">
                  Innovation fuels our journey. We embrace cutting-edge
                  technology to transform the way you discover and engage with
                  fashion. From personalized recommendations to immersive
                  experiences, we're at the forefront of redefining e-commerce.
                </div>
              </div>
              <div className="wrapper12">
                <div className="innerText14">Personalization</div>
                <div className="innerText15">
                  Your style is unique, and so is your shopping experience on
                  Hyprcom. We leverage advanced algorithms to tailor
                  recommendations specifically to your preferences, making every
                  interaction a personalized delight.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`aboutPage2 ${showNavbar ? "blur" : ""}`}>
          <div id="wrapper3">
            <span className="innerText4">Future Vision</span>
          </div>
          <div className="az">
            <img src={icon5} alt="" id="icon1" />
          </div>
          <div className="wrapper4">
            <div className="innerText5">
              Shaping the Intersection of Fashion and Technology
            </div>
            <div className="innerText6">
              At Hyprcom, our vision extends beyond the ordinary. We envision a
              future where fashion and technology harmoniously merge, redefining
              the entire e-commerce shopping experience. Our mission is to
              upgrade not just your wardrobe but your entire fashion journey.
            </div>
            <div className="innerText6">
              Imagine a world where shopping is not a task but an adventure,
              where your fashion choices are not confined to trends but elevated
              by personalized recommendations. The future we envision is a realm
              where every user becomes a trendsetter, shaping and influencing
              the fashion landscape.
            </div>
            <div className="innerText6">
              Hyprcom's commitment to the future is reflected in our dedication
              to merging fashion seamlessly with technology. We aspire to create
              a platform where users don't just shop; they embark on a journey
              of self-expression and discovery. Through continuous innovation,
              personalized experiences, and a community-centric approach, we are
              shaping the next chapter of online shopping.
            </div>
            <div className="innerText6">
              Join us on this visionary quest, where fashion meets technology,
              and together, we'll redefine how the world experiences style and
              upgrades their fashion trends.
            </div>
            <div className="forGap">
              <div id="ac" onClick={handleShowWaitlistForm}>
                <NavLink>
                  <b id="ab">JOIN WAITLIST</b>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className={`faq ${showNavbar ? "blur" : ""}`}>
          <FAQContainer />
        </div>
        <div className={`frame-container ${showNavbar ? "blur" : ""}`}>
          <StayInTheLoopSection1 />
          <BottomFooter />
        </div>
      </div>
    </div>
  );
};

export default About;
