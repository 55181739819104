import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Property1Default.css";
import BrandForm from "./BrandForm";

const Property1Default = ({setIsBrandFormOpen}) => {

  const [showBrandForm, setShowBrandForm] = useState(false);

  const handleShowBrandForm = () => {
    setIsBrandFormOpen(true);
  };

  return (
    <NavLink className="nav-partner max-[400px]:w-1/2" onClick={handleShowBrandForm}>
      <div className="rounded-md border border-[#00C853] text-center px-6 max-[400px]:px-0 py-3 font-bold text-[18px] leading-7 max-[720px]:text-[14px] max-[720px]:leading-6 max-[720px]:whitespace-nowrap text-[#FFFFFF] hover:border-[#13ED6D] hover:shadow-[0px_0px_12px_#FFE0E040]" >
        <b className="become-partner">BECOME PARTNER</b>
      </div>
      {showBrandForm && (
        <BrandForm
          onClose={() => setShowBrandForm(false)}
          setIsBrandFormOpen={false}
        />
      )}
    </NavLink>
  );
};

export default Property1Default;
